@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-pattern {
    background-image: linear-gradient(90deg, #e0e0e0 1px, transparent 1px),
                      linear-gradient(180deg, #e0e0e0 1px, transparent 1px);
    background-size: 40px 40px;
  }

/* Main container background */
.bg-dark {
  background-color: #fff;
  position: relative;
  overflow: hidden;
}

/* Glow effect for middle card */
.bg-glow-center {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 400px;
  height: 400px;
  background: radial-gradient(circle, rgba(34,197,94,0.5) 0%, transparent 70%);
  transform: translate(-50%, -50%);
  z-index: 0;
  filter: blur(50px);
}

/* Curved lines */
.bg-curved-lines::before,
.bg-curved-lines::after {
  content: '';
  position: absolute;
  width: 600px;
  height: 600px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

/* Positioning curved lines */
.bg-curved-lines::before {
  top: -200px;
  left: -200px;
}

.bg-curved-lines::after {
  bottom: -200px;
  right: -200px;
}

@keyframes gradientShift {
  0% {
    background-position: 0% 0%;
  }
  25% {
    background-position: 100% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  75% {
    background-position: 0% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

.color-shift-animated {
  background: linear-gradient(45deg, #A8E6CE , #eeda97, #4CAF50, #efe8a8 );
  background-size: 400% 400%; /* Makes the gradient move smoothly across the card */
  animation: gradientShift 6s ease infinite; /* Slow and smooth movement */
}

/* WaveBackground.css */
.wave-container {
  position: relative;
  width: 100%;
  height: 100vh;
  background: linear-gradient(180deg, #fff 0%, #dcfce7 100%);
  overflow: hidden;
}

.wave {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.footer {
  color: #000;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  text-align: center;
}

.footer-section {
  margin: 10px;
  flex: 1 1 200px;
}

.footer-section h3 {
  font-size: 1.1em;
  margin-bottom: 10px;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  margin: 5px 0;
}

.footer-section ul li a {
  color: #000;
  text-decoration: none;
}

.social-icons a {
  margin: 0 10px;
  color: #000;
  text-decoration: none;
}

.footer-bottom {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}